.imageButton
    color: #28bf3c !important
    text-transform: capitalize 
    

.container
    display: flex
    align-items: center
.section
    padding: 10px 50px

.photos
    margin-top: 10px
    border-radius: 20px
    border: 1px solid black
    width: 150px
    text-align: center
    height: 150px
    display: flex
    align-items: center
    justify-content: center

.withOutPhoto
    color: gray

.withOutAvatar
    color: gray
    width: 150px
    height: 150px
    border: 1px solid black
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center

.logoText
    text-align: center

.photosWrapper
    margin-top: 50px
    display: flex
    flex-wrap: wrap

.imgs
    margin: 10px

.text
    color: #28bf3c
    align-items: center
    text-transform: capitalize 