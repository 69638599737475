

.isComplete
    margin-top: 10px
    background-color: #F2DC8E
    padding: 15px
    border-radius: 10px
    display: flex
    width: 888px
    justify-content: space-between
    align-items: center

.progress
    width: 20%

.main
    background-color: white
    margin: 30px 0px
    border-radius: 10px